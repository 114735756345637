<template>
  <div class="card-view my-2">
    <div class="card-header">
      <router-link :to="{ name: 'SinglePerson', params: { id: person.id } }">
        <img
          v-if="person.profile_path"
          class="img-fluid w-100"
          :src="size + person.profile_path"
          :title="person.name"
          :alt="person.name"
        />
        <img
          v-else
          class="img-fluid w-100"
          src="../../assets/no_image.jpg"
          :title="person.name"
          :alt="person.name"
        />
      </router-link>
    </div>
    <div class="card-movie-content">
      <div class="card-movie-content-head">
        <router-link
          :to="{ name: 'SinglePerson', params: { id: person.id } }"
          class="d-block text-center"
          v-text="person.name"
        ></router-link>
        <div class="ratings" v-text="person.popularity"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cardViewPerson",
  props: {
    person: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped></style>
